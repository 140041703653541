:root {
    --background-color: #fefefe;
    --text-color: #3f3f3f;
}

.dark-mode {
    --background-color: #282c34;
    --text-color: #ffffff
}

body {
    margin: 0;
    font-family: 'Lato', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--background-color);
    color: var(--text-color);
}
