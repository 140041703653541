.App {
    text-align: center;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    gap: 32px;
}

.TextComponent {
    padding: 0 16px;
    display: flex;
    flex-direction: column;
    gap: 32px
}

.title, .subTitle {
    margin-bottom: 8px;
}

.title {
    font-size: clamp(2rem, 6vw, 3rem);
    font-weight: bold;
    font-family: PlayfairDisplay, serif;
}

.subTitle {
    font-size: 32px;
    font-weight: bold
}

.desc {
    font-size: 20px;
}

.buttonStyle {
    cursor: pointer;
    background-color: #4aa080;
    border: 2px solid transparent;
    border-radius: 4px;
    color: white;
    padding: 8px;
    font-size: 16px;
    font-family: 'Lato', sans-serif;
}

.buttonStyle:hover {
    background-color: #81bca6;
}

.buttonStyle:active {
    background-color: #267e66;
}

.clockSvg {
    height: 40vmin;
}

.ImpressumComponent {
    margin-bottom: 16px;
}

.impressumLink {
    color: #4aa080;
    text-decoration-thickness: 2px;
    text-decoration-color: #4aa0804d;
    text-underline-offset: 4px;
    transition: text-decoration-color 200ms ease-in-out;
}

.impressumLink:hover {
    text-decoration-color: #4aa080;
}

